<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            :plantCd="searchParam.plantCd"
            type="search" 
            :isFirstValue="false" 
            label="LBLDEPT" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진 기간"
            name="checkupPeriod"
            v-model="searchParam.checkupPeriod"
          />
        </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            :comboItems="cycleItems"
            name="checkTypeCd"
            label="검진구분"
            v-model="searchParam.checkTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12~ col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            name="uploadPeriod"
            label="업로드 기간"
            :range="true"
            v-model="searchParam.uploadPeriod"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12~ col-md-3 col-lg-3 col-xl-3">
          <c-field
            name="regUserId"
            label="업로드 담당자"
            v-model="searchParam.regUserId"
          ></c-field>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="검진결과 목록"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :gridHeight="grid.height"
      selection="multiple"
      :data="grid.data"
      rowKey="heaCheckupResultId"
      :columnSetting="false"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="엑셀 업로드" icon="upload"  @btnClicked="openExcelUploader"/>
          <!-- <c-btn label="업로드별 삭제" v-if="editable" icon="delete_forever" @btnClicked="removeUpload" /> -->
          <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-toolbar class="bg-blue-7 text-white shadow-2">
          <q-toolbar-title>삭제할 업로드 선택(최근 10개)</q-toolbar-title>
          <q-btn 
            rounded 
            size="sm" 
            icon="close" 
            @click="cancel" 
          />
        </q-toolbar>
        <q-list separator>
          <q-item 
            v-for="(item, index) in uploadList"
            :key="index"
            bordered clickable v-ripple
            @click="remove(item)">
            <q-item-section>
              <q-item-label overline class="text-orange-9 labelArea"><b>{{ item.uploadTitle }}</b></q-item-label>
              <q-item-label caption>{{ item.regDt + ' (' + item.regUserDeptName + '/' + item.regUserName + ')' }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <!-- <q-card-actions align="right" class="text-primary">
          <q-btn 
            rounded 
            size="sm" 
            icon="login" 
            style="widh:100%" 
            type="submit"
            label="삭제" 
            @click="remove" 
          />
          <q-btn 
            rounded 
            size="sm" 
            icon="close" 
            style="widh:100%" 
            label="취소" 
            @click="cancel" 
          />
        </q-card-actions> -->
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-result',
  data() {
    return {
      cycleItems: [
        { code: 'normal', codeName: '일반' },
        { code: 'special', codeName: '특수' },
        { code: 'batchBefore', codeName: '배치전' },
        { code: 'batchAfter', codeName: '배치후' },
      ],
      prompt: false,
      grid: {
        merge: [
          { index: 0, colName: 'userId' },
          { index: 1, colName: 'userId' },
          { index: 2, colName: 'userId' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'checkTypeName',
            field: 'checkTypeName',
            label: '검진구분',
            style: 'width: 100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            label: '업로드 정보',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'uploadDate',
                field: 'uploadDate',
                label: '일시',
                align: 'center',
                style: 'width: 100px',
                sortable: false,
              },
              {
                name: 'uploadTitle',
                field: 'uploadTitle',
                label: '제목',
                align: 'left',
                style: 'width: 100px',
                sortable: false,
              },
              {
                name: 'regUserDeptName',
                field: 'regUserDeptName',
                label: '담당자',
                align: 'center',
                style: 'width: 100px',
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        userId: '',
        checkupPeriod: [],
        hospitalId: null,
        uploadPeriod: [],
        regUserId: '',
      },
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      saveUrl: '',
      isSave: false,
      uploadList: [],
      uploadSaveUrl: '',
    };
  },
  computed: {
    isHeaDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000026') > -1 
    },
  },  
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.result.list.url;
      this.deleteUrl = transactionConfig.hea.checkup.result.delete.url;
      this.uploadListUrl = selectConfig.hea.checkup.result.uploadList.url;
      this.uploadSaveUrl = transactionConfig.hea.checkup.status.uploadSave.url;
      this.uploadDeleteUrl = transactionConfig.hea.checkup.result.uploadDelete.url;


      // if (!this.isHeaDept) {
      //   this.searchParam.userId = this.$store.getters.user.userId
      // }

      this.getList();
      // this.getUploadList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.getUploadList();
    },);
    },
    getUploadList() {
      this.$http.url = this.uploadListUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.uploadList = _result.data;
      },);
    },
    removeUpload() {
      this.prompt = true;
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?', 
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    remove(item) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', 
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.uploadDeleteUrl, item.heaCheckupResultUploadId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.prompt = false;
            // this.getUploadList();
            this.getList();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    cancel() {
      this.prompt = false;
    },
    openExcelUploader() {
      this.popupOptions.title = '대상자별 검진결과 업로드';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./checkUpStatusExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result, _data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        
        this.$_.forEach(s_data, item => {
          item.uploadTitle = _data.uploadTitle
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          item.editFlag = 'C';
          let idx = this.$_.findIndex(this.cycleItems, {codeName: item.checkTypeName})
          if (idx > -1) {
            item.checkTypeCd = this.cycleItems[idx].code
          } else {
            item.checkTypeCd = null;
          }
          let idx2 = this.$_.findIndex(this.$store.state.systemInfo.users, {empNo: item.empNo})
          if (idx2 > -1) {
            item.userId = this.$store.state.systemInfo.users[idx2].userId
          } 
        })
        this.$http.url = this.uploadSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          this.getList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    }
  }
};
</script>
<style>
.labelArea {
  font-size: 17px
}
</style>